import ErrorPage from '@source/components/ErrorPage';
import MainLayout from '@source/components/MainLayout';
import Head from 'next/head';

const NotFound: React.FC = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>Uh oh... A página que estás procurando não existe.</title>
      </Head>
      <MainLayout renderErrorPage>
        <ErrorPage errorMessage="A página que estás procurando não existe." />
      </MainLayout>
    </>
  );
};

export default NotFound;
